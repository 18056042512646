import {Button, Modal, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRotateForward,
    faAdd,
    faImage,
    faEdit,
    faTrashCan,
    faSort
} from "@fortawesome/free-solid-svg-icons";
import {fetchProps, swalDefaultError} from "../config";
import Swal from "sweetalert2";
import AddGallery from "./AddGallery";
import {useState} from "react";
import ReorderList from "./ReorderList";

export default function GalleryTable(props) {

    const {section, galleries, setGalleries, setEditGallery, handleBackToSelectSectionClick, sectionIsBlog, reloadGalleries, mySettings} = props;

    const serverFilesRoot = mySettings.serverFilesRoot;

    const [showModal, setShowModal] = useState(false);
    const [showSortModal, setShowSortModal] = useState(false);
    const [newGalleryOrder, setNewGalleryOrder] = useState(galleries);

    const handleCloseModal = async () => {
        await reloadGalleries(section.id);
        setShowModal(false)
    };
    const handleShowModal = () => setShowModal(true);

    const handleShowSortModal = () => setShowSortModal(true);
    const handleCloseSortModal =  () => {
        setNewGalleryOrder(galleries);
        setShowSortModal(false)
    }

    const handleSaveNewOrderClick = async () => {
        for (const [index,gallery] of newGalleryOrder.entries()) {
            const updatedGallery = {
                id: gallery._id,
                sequence: index
            }
            const response = await fetch('/admin/gallery', fetchProps('PUT', updatedGallery))
            const parsedResponse = await response.json();

            if (response.status !== 200) {
                await Swal.fire(swalDefaultError(parsedResponse.errorCode))
                await reloadGalleries(section.id)
                break;
            }
        }

        await reloadGalleries(section.id)
        setShowSortModal(false)
    }

    const handlePublishGalleryClick = async (galleryId, published) => {

        const gallery = {
            id: galleryId,
            published: !published,
        }

        const response = await fetch('/admin/gallery', fetchProps('PUT', gallery))
        const parsedResponse = await response.json();

        if (response.status === 200) {
            await reloadGalleries(section.id);
        } else {
            await Swal.fire(swalDefaultError(parsedResponse.errorCode))
        }
    }

    const handleManageGalleryClick = async (id, title, text, firstImage) => {
        const response = await fetch('/admin/gallery/'+id, fetchProps('GET'));
        const {gallery} = await response.json();
        setEditGallery({images: gallery, title: title, text: text, id: id, firstImage: firstImage})
    }


    const handleDeleteGalleryClick = async (gallery) => {

        const doWhat = await Swal.fire({
            text: `Smazat galerii "${gallery.title}" včetně všech jejích fotografií?`,
            icon: 'question',
            cancelButtonText: 'Ne',
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: '#BB2D3B',
            confirmButtonText: 'Ano',
        })
        if (doWhat.isConfirmed) {

            const response = await fetch('admin/gallery/' + gallery._id, fetchProps('DELETE'));
            const parsedResponse = await response.json();

            if (response.status === 200) {
                await reloadGalleries(section.id);
            } else {
                await Swal.fire(swalDefaultError(parsedResponse.errorCode))
            }
        }
    }

    const _sortGalleries = (galleries) => {
        if (sectionIsBlog) {
            return galleries.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
        }
        else {
            return galleries.sort((a, b) => {
                return a.sequence - b.sequence;
            });
        }
    }

    return (
        <>
            <Button variant={'warning mt-4'} onClick={handleBackToSelectSectionClick}>
                <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;&nbsp;&nbsp;zpět na výběr kategorie
            </Button>
            <Button variant={'primary mt-4'} style={{float:'right'}} onClick={handleShowModal}>
                <FontAwesomeIcon icon={faAdd}/>
                {!sectionIsBlog && <span>&nbsp;&nbsp;&nbsp;vložit novou galerii</span>}
                {sectionIsBlog && <span>&nbsp;&nbsp;&nbsp;vložit nový příspěvek</span>}
            </Button>
            {!sectionIsBlog && galleries.length > 1 && <Button variant={'outline-danger'} className={'mt-4'} style={{float: 'right', marginRight: '1rem'}} onClick={handleShowSortModal}>
                <FontAwesomeIcon icon={faSort}/>
                <span>&nbsp;&nbsp;&nbsp;pořadí galerií</span>
            </Button>}

            <hr className={'content-lines-wrapper'} />
            <h2 className={'mb-4'}>{section.label}</h2>
            <Table striped bordered variant={'dark'}>
                <thead>
                    <tr>
                        <th>název</th>
                        <th>založeno</th>
                        <th>úvodní&nbsp;obrázek</th>
                        <th className={'text-center'}>publikovat</th>
                        <th className={'text-center'}>upravit</th>
                        <th className={'text-center'}>smazat</th>
                    </tr>
                </thead>
                <tbody>
                {_sortGalleries(galleries).map(gallery => {
                    return (
                        <tr>
                            <td className={'align-middle'}>{gallery.title}</td>
                            <td className={'align-middle'}>{new Date(gallery.createdAt).toLocaleDateString() + ' ' + new Date(gallery.createdAt).toLocaleTimeString()}</td>
                            <td>
                                {gallery.firstImagePath && <img src={serverFilesRoot + gallery.firstImagePath} alt={''} style={{maxHeight: '100px'}}/>}
                                {!gallery.firstImagePath && <FontAwesomeIcon icon={faImage} size={"xl"}/>}
                            </td>
                            <td className={'align-middle text-center'}>{gallery.published ?
                                <Button variant={'outline-danger'} size={'sm'} onClick={() => handlePublishGalleryClick(gallery._id,gallery.published)}>skrýt</Button> :
                                <Button variant={'success'} size={'sm'} onClick={() => handlePublishGalleryClick(gallery._id, gallery.published)}>publikovat</Button>
                            }
                            </td>
                            <td className={'align-middle text-center'}><Button variant={'warning'} size={'sm'} onClick = {() => handleManageGalleryClick(gallery._id, gallery.title, gallery.text, gallery.firstImagePath)}><FontAwesomeIcon icon={faEdit}/></Button></td>
                            <td className={'align-middle text-center'}><Button onClick={() => handleDeleteGalleryClick(gallery)} variant={'danger'} size={"sm"}><FontAwesomeIcon icon={faTrashCan}/></Button></td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            {!sectionIsBlog && galleries.length === 0 && <p>nejsou zde žádné galerie..</p>}
            {sectionIsBlog && galleries.length === 0 && <p>nejsou zde žádné příspěvky..</p>}

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size={'xl'}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Vložit do sekce <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>{section.label}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddGallery
                        mySettings={mySettings}
                        section={section}
                        sectionIsBLog={sectionIsBlog}
                        setGalleries={setGalleries}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* SORT modal*/}
            <Modal
                show={showSortModal}
                onHide={handleCloseSortModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>Změnit pořadí galeríí v sekci {section.label}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReorderList
                        newOrder={newGalleryOrder}
                        setNewOrder={setNewGalleryOrder}
                        serverFilesRoot={serverFilesRoot}
                        sortWhat={'galleries'}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSortModal}>
                        Zrušit
                    </Button>
                    <Button variant="success" onClick={handleSaveNewOrderClick}>
                        Uložit a zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}