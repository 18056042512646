import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {Link, useLocation} from "react-router-dom";
import Swal from 'sweetalert2'
import {fetchProps} from "../config";

export default function MyNav(props) {

    const location = useLocation();
    const {logout, mySettings} = props;
    const serverFilesRoot = mySettings.serverFilesRoot;

    const handleLogout = async () => {
        const doWhat = await Swal.fire({
            title: 'Ukončit aplikaci?',
            icon: 'question',
            cancelButtonText: 'Ne',
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: '#BB2D3B',
            confirmButtonText: 'Ano',
        })
        if (doWhat.isConfirmed) {
            logout();
        }
    }

    const deleteAll = async () => {
        const doWhat = await Swal.fire({
            title: 'Smazat vše?',
            text: 'smaže všechny fotografie z databáze i ze serveru',
            icon: 'question',
            cancelButtonText: 'Ne',
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: '#BB2D3B',
            confirmButtonText: 'Ano',
        })
        if (doWhat.isConfirmed) {
            await fetch('admin/delete/all', fetchProps());
            logout();
        }
    }

    return (
        <Navbar bg="dark" variant={'dark'} expand="lg">
            <Container>
                <Navbar.Brand>
                    <Nav.Link as={Link} to="/" >
                        <img src={serverFilesRoot + 'images/ludvida-white.png'} height={40} width={'auto'} className="d-inline-block align-top" alt="Ludvida" />
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey={location.pathname} className="container-fluid">
                        {/*<NavItem eventkey={1} href="/">*/}
                        {/*    <Nav.Link as={Link} to="/" >Příspěvky</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem eventkey={2} href="/gallery">*/}
                        {/*    <Nav.Link as={Link} to="/gallery" >Galerie</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem eventkey={3} href="/exhibit">*/}
                        {/*    <Nav.Link as={Link} to="/exhibit" >Výstavy</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem eventkey={4} href="/contents">*/}
                        {/*    <Nav.Link as={Link} to="/contents" >Obsah</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem eventkey={5} href="/settings">*/}
                        {/*    <Nav.Link as={Link} to="/settings" >Nastavení</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem eventkey={6} href="/add-gallery">*/}
                        {/*    <Nav.Link as={Link} to="/add-gallery" >Přidat Galerii</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem eventkey={7} href="/manage-gallery">*/}
                        {/*    <Nav.Link as={Link} to="/manage-gallery" >Upravit Galerii</Nav.Link>*/}
                        {/*</NavItem>*/}
                        {mySettings.testMode === 'true' && <Nav.Item eventkey={8} className="ms-auto">
                            <Nav.Link onClick={() => deleteAll()}>Smazat vše</Nav.Link>
                        </Nav.Item>}
                        <Nav.Item eventkey={9} className="ms-auto" href="/enhance">
                            <Nav.Link as={Link} to="/enhance" >Vylepšit s AI</Nav.Link>
                        </Nav.Item>
                        <Nav.Item eventkey={8} className="ms-auto">
                            <Nav.Link onClick={() => handleLogout()}>Ukončit</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}