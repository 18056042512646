import {buttonVariants, fetchProps, swalDefaultError} from "../config";
import {Button} from "react-bootstrap";
import Swal from "sweetalert2";
import NewsForm from "./NewsForm";

export default function SectionSelect(props) {

    const {setSection, setGalleries, setSectionIsBlog, setSectionIsExhibit, setEditGallery, reloadGalleries, mySettings} = props;

    const availableSections = mySettings.sections;

    const handleSectionButtonClick = async (item) => {

        const response = await fetch('/admin/section/'+item.id, fetchProps());
        const {galleries} = await response.json();

        // if there are no subsections we need to create a gallery in case it does not exist
        if (item.hasSubSection) {
            setGalleries(galleries);
        } else {
            if (galleries.length > 0) {
                const responseGallery = await fetch('/admin/gallery/'+ galleries[0]._id, fetchProps('GET'));
                const {gallery} = await responseGallery.json();
                setGalleries(galleries);
                setEditGallery({images: gallery, title: item.label, text: null, id: galleries[0]._id, firstImage: galleries[0].firstImagePath})
            } else {

                const gallery = {
                    title: item.label,
                    text: null,
                    category: item.label,
                    categoryId: item.id,
                    categoryUrl: item.url,
                    published: true,
                    createdAt: Date.now()
                }


                const responseCreateGallery = await fetch('/admin/gallery', fetchProps('POST', gallery))
                const parsedResponse = await responseCreateGallery.json();

                if (responseCreateGallery.status === 200) {
                    const {savedGallery} = parsedResponse;
                    await reloadGalleries(item.id);
                    setEditGallery({images: [], title: item.label, text: null, id: savedGallery._id, firstImage: savedGallery.firstImagePath})
                } else {
                    await Swal.fire(swalDefaultError(parsedResponse.errorCode))
                }
            }
        }

        setSection(item)

        switch (item.id) {
            case 1:
                setSectionIsBlog(true);
                setSectionIsExhibit(false);
                break;
            case 17:
                setSectionIsBlog(true);
                setSectionIsExhibit(false);
                break;
            case 16:
                setSectionIsBlog(false);
                setSectionIsExhibit(true);
                break;
            default:
                setSectionIsBlog(false);
                setSectionIsExhibit(false);
        }
    }


    return (
        <>
        <section className={'p-4 m-4 border rounded-3 bg-dark'}>
            {availableSections.map((section, index) => {
                return (
                    <div key={index}>
                        <div style={{display: 'flex'}} className={'justify-content-center'}>
                            {
                                section.map((item, i) => {
                                    if (item.label === 'Výstavy') return <span key={i}/>
                                    return (
                                        <Button key={i} variant={buttonVariants[index]} className={'m-1'} onClick={() => handleSectionButtonClick(item)}>{item.label}</Button>
                                    )
                                })}
                        </div>
                    </div>    )
            })}
        </section>
            <section className={'p-4 m-4 border rounded-3 bg-light'}>
                <h4 style={{textAlign: "center", marginBottom: '2rem'}}>Co je nového</h4>
                <NewsForm newsId={'news'} />
                <NewsForm newsId={'news_2'} />
            </section>
        </>
    );
}