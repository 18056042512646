import React, { useState } from 'react';
import { Container, Form, Button, Image as BootstrapImage, Alert, Spinner } from 'react-bootstrap';
import { access } from "../config";

export default function Enhance() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [multiplier, setMultiplier] = useState(1.5);
    const [imageResolution, setImageResolution] = useState(null);
    const [processedFileLink, setProcessedFileLink] = useState(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);

    // Handle image upload and check resolution
    const handleImageChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedImage(file);
            setWarning(null);

            const reader = new FileReader();

            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    const width = img.width;
                    const height = img.height;
                    const totalPixels = width * height;

                    if (totalPixels > 2096704) {
                        setWarning('Rozlišení je příliš vysoké, maximální počet pixelů je 2 096 704, což odpovídá například rozlišení 1920 x 1080');
                        setImagePreviewUrl(null); // Clear image preview if there's a warning
                        setImageResolution(null); // Clear image resolution if there's a warning
                        return;
                    } else {
                        setImageResolution({ width, height });
                        setImagePreviewUrl(e.target.result);
                    }
                };
                img.src = e.target.result;
            };

            reader.readAsDataURL(file);
        }
    };

    // Handle form submission for image upload and slider value
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedImage) {
            setError('Není vybrán obrázek');
            return;
        }

        setLoading(true);
        resetForm(); // Reset form before processing new request

        const formData = new FormData();
        formData.append('image', selectedImage);
        formData.append('multiplier', multiplier);
        const accessToken = sessionStorage.getItem(access.sessionStorageKey);

        try {
            const response = await fetch('/admin/enhance-img', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formData
            });

            const data = await response.json();

            if (response.ok) {
                const fileUrl = data.processedFileLink; // Ensure that 'processedFileLink' is in data
                setSuccess('Obrázek byl úspěšně zpracován.');

                // Trigger file download
                if (fileUrl) {
                    // Fetch the file data as a blob
                    const fileResponse = await fetch(fileUrl);
                    const fileBlob = await fileResponse.blob();
                    const fileName = fileUrl.split('/').pop(); // Extract filename from URL or set a default name

                    // Create a new object URL
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(fileBlob);
                    a.download = fileName; // Use filename from URL or set a default filename
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    // Revoke the object URL to free up memory
                    URL.revokeObjectURL(a.href);
                }

                // Clear previous errors and warnings
                setError(null);
                setWarning(null);
            } else {
                setError(data.message || 'Failed to process image');
                setProcessedFileLink(null);
                setSuccess(null); // Clear any previous success message
            }
        } catch (error) {
            setError('Error processing image');
            setProcessedFileLink(null);
            setSuccess(null); // Clear any previous success message
        } finally {
            setLoading(false);
        }
    };
    // Reset form to original state
    const resetForm = () => {
        setSelectedImage(null);
        setImagePreviewUrl(null);
        setMultiplier(1.5);
        setImageResolution(null);
        setProcessedFileLink(null);
        setError(null);
        setWarning(null);
        setSuccess(null); // Clear success message
    };

    return (
        <Container>
            {/* Error, Warning, and Success Alerts */}
            {error && <Alert variant="danger">{error}</Alert>}
            {warning && <Alert variant="warning">{warning}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            {/* Image Upload Form */}
            {!processedFileLink && (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formFile" className="m-3">
                        <Form.Label>Nahrát obrázek</Form.Label>
                        <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                    </Form.Group>

                    {/* Display Image Preview and Resolution */}
                    {imagePreviewUrl && imageResolution && (
                        <div className="m-3">
                            <BootstrapImage src={imagePreviewUrl} alt="Preview" fluid />
                            <p className="mt-3">Rozlišení: {imageResolution.width} x {imageResolution.height}</p>
                        </div>
                    )}

                    {/* Slider to adjust the resolution multiplier */}
                    <Form.Group controlId="resolutionSlider" className="m-3">
                        <Form.Label>Násobič rozlišení</Form.Label>
                        <Form.Control
                            type="range"
                            min={1.5}
                            max={4.0}
                            step={0.01}
                            value={multiplier}
                            onChange={(e) => setMultiplier(parseFloat(e.target.value))}
                        />
                        <p className="mt-3">Násobič: {multiplier.toFixed(2)}</p>
                        {/* Display multiplied resolution */}
                        {imageResolution && (
                            <p className="mt-3">
                                Výsledné rozlišení: {Math.round(imageResolution.width * multiplier)} x {Math.round(imageResolution.height * multiplier)}
                            </p>
                        )}
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={!selectedImage || !!warning || loading} className="m-3">
                        {loading ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" />{' '}
                                Zpracovávám...
                            </>
                        ) : (
                            'Odeslat'
                        )}
                    </Button>
                </Form>
            )}
        </Container>
    );
}
