import {Outlet} from "react-router-dom";
import MyNav from "../components/MyNav"

export default function Layout(props) {

    return (
        <>
            <MyNav logout={props.logout} mySettings={props.mySettings}/>
            <div className={'content__wrapper'}>
                <Outlet />
            </div>
        </>
    );
}