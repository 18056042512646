import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import {fetchProps, swalDefaultError} from "../config";
import {useState} from "react";
import Swal from "sweetalert2";

export default function Login(props) {

    const {login} = props;

    const loginServer = async (name, password) => {

        const fetchBody = {name: name, password: password};
        // login to Node.js server
        const response = await fetch(`/admin/user/login`, fetchProps('POST', fetchBody, false));
        const parsedData = await response.json();

        if (response.status === 200) {
            if (parsedData.accessToken) {
                login(parsedData.accessToken);
            }
        } else {
            Swal.fire(swalDefaultError(parsedData.errorCode));
        }

    }

    const handleLoginFormSubmit = async event => {
        event.preventDefault();
        const name = event.target.loginName.value;
        const password = event.target.loginPassword.value;
        await loginServer(name, password);
    }


    return (
        <div className={'container col-4 mt-3'}>
            <Form onSubmit={(event) => {
                handleLoginFormSubmit(event);
            }}>
                <Form.Group className="mb-3" controlId="loginName">
                    <Form.Label>Uživatelské jméno</Form.Label>
                    <Form.Control type="text" placeholder="Zadejte uživatelské jméno" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="loginPassword">
                    <Form.Label>Heslo</Form.Label>
                    <Form.Control type="password" placeholder="Heslo" required />
                </Form.Group>
                <Button variant="primary" type="submit" className={'mt-3'}>
                    Přihlásit se
                </Button>
            </Form>
        </div>
    )
}