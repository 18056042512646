import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import Manager from "./pages/Manager";
import Enhance from "./pages/Enhance";
import {useEffect, useState} from "react";
import {access, fetchProps, swalDefaultError} from "./config";
import Swal from "sweetalert2";

/**
 * App router
 * @returns {JSX.Element}
 */
export default function App() {

  const [token, setToken] = useState(sessionStorage.getItem(access.sessionStorageKey));
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [mySettings, setMySettings] = useState([]);

  useEffect(() => {
      if (token) {
          getSettings();
      }
  },[])

  const login = async (token) => {
      sessionStorage.setItem(access.sessionStorageKey, token);
      setToken(token);
      await getSettings();
  }

  const logout = () => {
      sessionStorage.removeItem(access.sessionStorageKey);
      setToken(null);
  }

  const getSettings = async () => {
      const response = await fetch('/admin/settings', fetchProps())
      const parsedResponse = await response.json();


      if (response.status === 200) {
          setMySettings(parsedResponse.settings);
          setDataIsLoaded(true);
      } else {
          await Swal.fire(swalDefaultError(parsedResponse.errorCode))
      }
  }

  if (!token) {
      return <Login login={login} />
  }

  return (

    <BrowserRouter>
        <Routes>
            {dataIsLoaded && <Route path="/" element={<Layout logout={logout} mySettings ={mySettings}/>}>
                <Route index element={<Manager mySettings ={mySettings}/>}/>
                <Route path="enhance" element={<Enhance />} />
                <Route path="*" element={<NoPage/>}/>
            </Route>}
        </Routes>
    </BrowserRouter>
  );
}
