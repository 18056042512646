export const access = {
    sessionStorageKey: 'accessToken',
    user: 'userName'
};

// !!! different on PROD
// export const serverFilesRoot = 'http://localhost:3000/';
// export const serverFilesRoot = 'https://www.ludvida.cz/';

export const fetchProps = (method = 'GET', body = {}, auth = true) => {
    let props = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
    }
    if(auth) {
        const accessToken = sessionStorage.getItem(access.sessionStorageKey);
        props.headers.authorization = `Bearer ${accessToken}`;
    }
    if (Object.keys(body).length > 0) {
        props.body = JSON.stringify(body);
    }
    return props;
}

export const editorConfig = {
    language: {
        ui: 'cs',
        content: 'cs'
    }
}

export const swalDefaultError = (errorCode) => {
    return {
        icon: 'error',
        title: 'Došlo k chybě',
        text: errorCode,
        confirmButtonText: 'Co se dá dělat',
    }
}

export const buttonVariants = ['outline-info','outline-light','outline-success', 'outline-warning', 'outline-danger'];


// export const gallerySectionsArray = [
//     [
//         {
//             id: 1,
//             label: 'Aktuálně',
//             url: 'aktualne',
//             hasSubSection: true,
//         }
//     ],
//     [
//         {
//             id: 2,
//             label: 'Kompozice',
//             url: 'tvurci-fotografie/kompozice',
//             hasSubSection: true,
//         },
//         {
//             id: 3,
//             label: 'Voda, led',
//             url: 'tvurci-fotografie/voda-led',
//             hasSubSection: true
//         },
//         {
//             id: 4,
//             label: 'S přispěním člověka',
//             url: 'tvurci-fotografie/s-prispenim-cloveka',
//             hasSubSection: true,
//         },
//         {
//             id: 5,
//             label: 'Z kuchyně',
//             url: 'tvurci-fotografie/z-kuchyne',
//             hasSubSection: false,
//         },
//         {
//             id: 6,
//             label: 'Hry s duhou',
//             url: 'tvurci-fotografie/hry-s-duhou',
//             hasSubSection: false,
//         },
//         {
//             id: 7,
//             label: 'Ostatní',
//             url: 'tvurci-fotografie/ostatni',
//             hasSubSection: false,
//         }
//     ],
//     [
//         {
//             id: 8,
//             label: 'Orlické hory',
//             url: 'priroda/orlicke-hory',
//             hasSubSection: true,
//         },
//         {
//             id: 9,
//             label: 'Z lesa',
//             url: 'priroda/les',
//             hasSubSection: true,
//         },
//         {
//             id: 10,
//             label: 'Kytičky',
//             url: 'priroda/kyticky',
//             hasSubSection: true,
//         },
//         {
//             id: 11,
//             label: 'Design příroda',
//             url: 'priroda/design',
//             hasSubSection: false,
//         },
//         {
//             id: 12,
//             label: 'Houby',
//             url: 'priroda/houby',
//             hasSubSection: true,
//         },
//         {
//             id: 13,
//             label: 'Malí a velcí',
//             url: 'priroda/mali-a-velci',
//             hasSubSection: true,
//         },
//         {
//             id: 14,
//             label: 'Zajímavosti',
//             url: 'priroda/zajimavosti',
//             hasSubSection: false,
//         },
//     ],
//     [
//         {
//             id: 15,
//             label: 'Žertoviny',
//             url: 'zertoviny',
//             hasSubSection: false,
//         }
//     ],
//     [
//         {
//             id: 16,
//             label: 'Výstavy',
//             url: 'vystavy',
//             hasSubSection: true,
//         }
//     ]
// ]
//
//
//
