import {Button} from "react-bootstrap";

export default function MyEmojiList() {

    const emojis = ['&#128578;', '&#129315;', '&#128516;', '&#127774;','&#127804;', '&#128151;','&#129318'];

    const handleSelectEmojiClick = async (event) => {
        await navigator.clipboard.writeText(event.target.innerHTML);
        event.target.classList.replace('btn-outline-light','btn-outline-success');
        setTimeout(() => {
            event.target.classList.replace('btn-outline-success','btn-outline-light');
        },2000)
    }

    return (
        <ul className="my-emoji-list">
            {emojis.map((emoji, index) => {
                return(
                    <li key={index}>
                        <Button variant={'outline-light'} size={'sm'} onClick={handleSelectEmojiClick} dangerouslySetInnerHTML={{ __html: emoji }} >
                        </Button>
                    </li>)
            })}
        </ul>
    );
}