import {useState, useEffect} from "react";

// Import React FilePond and its plugins
import { FilePond, registerPlugin } from 'react-filepond';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginImageSizeMetaData from 'filepond-plugin-image-size-metadata';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {access} from "../config";

// Register FilePond plugins
registerPlugin(
    // FilePondPluginImagePreview,
    FilePondPluginFileMetadata,
    FilePondPluginImageSizeMetaData,
    FilePondPluginImageResize,
    FilePondPluginImageTransform
);

export default function MyFileManager(props) {

    const [files, setFiles] = useState([]);
    const {gallery, mySettings} = props;

    const serverFilesRoot = mySettings.serverFilesRoot;

    useEffect(() => {
        files.forEach(file => file.setMetadata('realName', file.filename));
    },[files])

    const uploadServer = {
        url: '/admin/upload',
        process: {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem(access.sessionStorageKey)
            }
        }

    }

    const addShit = (canvas) => {
        // Do something with the canvas, like drawing some text on it

        const width = canvas.width;
        const height = canvas.height;

        // Do something with the canvas, like drawing some text on it
        const imageToDraw = document.createElement('img')
        imageToDraw.src = serverFilesRoot + 'images/watermark-logo-grey.png'

        const watermarkWidth = 150;
        const watermarkHeight = watermarkWidth * (imageToDraw.height / imageToDraw.width);

        const ctx = canvas.getContext('2d');
        // ctx.font = '48px serif';
        // ctx.fillText('BLA', 10, 50);

        ctx.drawImage(imageToDraw, width - watermarkWidth - 15, height - watermarkHeight - 5, watermarkWidth, watermarkHeight);


            // return canvas to the plugin for further processing
            return canvas
    }

    return (
        <FilePond

            name={'myFileInput'}
            // server={'/admin/upload'}
            server={uploadServer}
            files={files}
            maxFiles={50}
            onupdatefiles={setFiles}
            allowMultiple={true}
            allowFileMetadata={true}
            allowImageSizeMetadata={true}
            allowImageResize={true}
            allowImageTransform={true}
            allowRevert={false}
            imageResizeTargetWidth={1600}
            imageResizeTargetHeight={1200}
            imageResizeMode={'contain'}
            imageResizeUpscale={false}
            imageTransformOutputQuality={80}
            labelIdle={'klikněte zde pro nahrání fotografií k této galerii. je možno vybrat více souborů. po ukončení nahrávání můžete toto okno zavřít, nebo nahrát další fotografie'}
            fileMetadataObject={gallery}
            // imageTransformBeforeCreateBlob={addShit}
        >
        </FilePond>
    )
}

