import {Container} from 'react-bootstrap'
import {useState} from "react";

import SectionSelect from "../components/SectionSelect";
import GalleryTable from "../components/GalleryTable";
import ImageTable from "../components/ImageTable";
import {fetchProps, swalDefaultError} from "../config";
import Swal from "sweetalert2";

// TODO loader

export default function Manager(props) {

    const {mySettings} = props;

    // gallery section
    const [section, setSection] = useState(null);
    // galleries in section
    const [galleries, setGalleries] = useState([]);
    // individual gallery
    const [editGallery, setEditGallery] = useState(null)

    const [sectionIsBlog, setSectionIsBlog] = useState(null);
    const [sectionIsExhibit, setSectionIsExhibit] = useState(null);

    const handleBackToSelectSectionClick = () => {
        setSection(null)
        setEditGallery(null)
    }


    const reloadGalleries = async (sectionId) => {

        const response = await fetch('/admin/section/'+sectionId, fetchProps());
        const parsedResponse = await response.json();

        if (response.status === 200) {
            const {galleries} = parsedResponse;
            setGalleries(galleries);
        } else {
            await Swal.fire(swalDefaultError(parsedResponse.errorCode))
        }
    }

    return (
        <>
            <Container>
                {/*gallery section select*/}
                {!section && <SectionSelect
                    mySettings={mySettings}
                    section={section}
                    setSection={setSection}
                    setGalleries={setGalleries}
                    sectionIsBlog={sectionIsBlog}
                    setSectionIsBlog={setSectionIsBlog}
                    setSectionIsExhibit={setSectionIsExhibit}
                    setEditGallery={setEditGallery}
                    reloadGalleries={reloadGalleries}
                />}

                {/* gallery list */}
                {section && !editGallery && <GalleryTable
                    mySettings={mySettings}
                    galleries={galleries}
                    setGalleries={setGalleries}
                    section={section}
                    setSection={setSection}
                    sectionIsBlog={sectionIsBlog}
                    setEditGallery={setEditGallery}
                    handleBackToSelectSectionClick={handleBackToSelectSectionClick}
                    reloadGalleries={reloadGalleries}
                />}

                {/* gallery */}
                {section && editGallery && <ImageTable
                    mySettings={mySettings}
                    section={section}
                    galleries={galleries}
                    editGallery={editGallery}
                    setEditGallery={setEditGallery}
                    sectionIsBlog={sectionIsBlog}
                    sectionIxExhibit={sectionIsExhibit}
                    handleBackToSelectSectionClick={handleBackToSelectSectionClick}
                    reloadGalleries={reloadGalleries}
                />}
            </Container>
        </>
    );
}