import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {fetchProps, swalDefaultError} from "../config";
import Swal from "sweetalert2";

export default function NewsForm(props) {

    const {newsId} = props

    const [textInput, setTextInput] = useState('');
    const [linkInput, setLinkInput] = useState('');


    useEffect(() => {
        const getNews = async () => {
            const response = await fetch('/admin/settings/news/' + newsId, fetchProps());
            const news = await response.json();
            setTextInput(news.value);
            setLinkInput(news.link);
        }
        getNews();
    },[])


    const handleTextInputChange = (event) => setTextInput(event.currentTarget.value);
    const handleLinkInputChange = (event) => setLinkInput(event.currentTarget.value);


    const handleSaveNewsSubmit = async (event) => {
        event.preventDefault();
        let text = event.target.text.value;
        let link = event.target.link.value;

        text = text.trim() ?? null;
        link = link.trim() ?? null;

        const response = await fetch('/admin/settings/news/' + newsId, fetchProps('PUT', {news: {text: text, link: link}}))
        const parsedResponse = await response.json();

        if (response.status === 200) {

            event.target.saveNewsSubmitButton.classList.replace('btn-primary', 'btn-success')
            event.target.saveNewsSubmitButton.innerHTML = 'Uloženo'

            setTimeout(() => {
                event.target.saveNewsSubmitButton.classList.replace('btn-success', 'btn-primary')
                event.target.saveNewsSubmitButton.innerHTML = 'Uložit'
            },2000)

        } else {
            await Swal.fire(swalDefaultError(parsedResponse.errorCode))
        }
    }


    return (
        <Form onSubmit={handleSaveNewsSubmit}>
            <Row className="align-items-center">
                <Col xs="5">
                    <Form.Label htmlFor="text" visuallyHidden>
                        Text
                    </Form.Label>
                    <Form.Control
                        className="mb-2"
                        id="text"
                        placeholder="Text"
                        value={textInput}
                        onChange={handleTextInputChange}
                    />
                </Col>
                <Col xs="5">
                    <Form.Label htmlFor="link" visuallyHidden>
                        Odkaz
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Text>@</InputGroup.Text>
                        <Form.Control
                            id="link"
                            placeholder="Odkaz"
                            value={linkInput}
                            onChange={handleLinkInputChange}
                        />
                    </InputGroup>
                </Col>
                <Col xs="auto">
                    <Button id='saveNewsSubmitButton' type="submit" className="mb-2">
                        Uložit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}