import {Button, Modal, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faCheckCircle,
    faEdit,
    faPlus,
    faRotateBack,
    faStar,
    faTrashCan,
    faSort
} from "@fortawesome/free-solid-svg-icons";
import {fetchProps, swalDefaultError} from "../config";
import Swal from "sweetalert2";
import {useState} from "react";
import EditGallery from "./EditGallery";
import ReorderList from "./ReorderList";

export default function ImageTable(props) {

    const {section, editGallery, setEditGallery, handleBackToSelectSectionClick, sectionIsBlog ,galleries, reloadGalleries, sectionIxExhibit, mySettings} = props;

    const serverFilesRoot = mySettings.serverFilesRoot;

    const [showModal, setShowModal] = useState(false);
    const [showSortModal, setShowSortModal] = useState(false);
    const [newImageOrder, setNewImageOrder] = useState(editGallery.images);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = async () => {
        await reloadGallery(editGallery.id)
        setShowModal(false)
    };

    const handleShowSortModal = () => setShowSortModal(true);
    const handleCloseSortModal =  () => {
        setNewImageOrder(editGallery.images);
        setShowSortModal(false)
    }
    const handleSaveNewOrderClick = async () => {
        for (const [index,image] of newImageOrder.entries()) {
            const updatedImage = {
                id: image._id,
                sequence: index
            }
            const response = await fetch('/admin/image', fetchProps('PUT', updatedImage))
            const parsedResponse = await response.json();

            if (response.status !== 200) {
                await Swal.fire(swalDefaultError(parsedResponse.errorCode))
                await reloadGallery(editGallery.id)
                break;
            }
        }

        await reloadGallery(editGallery.id)
        setShowSortModal(false)
    }

    const _sortImages = (images) => {
            return images.sort((a, b) => {
                return a.sequence - b.sequence;
            });
    }

    const handleBackToSectionClick = async () => {
        await reloadGalleries(section.id);
        setEditGallery(null);
        setNewImageOrder(null);
    }

    const handleDeleteImageClick = async (image) => {

        const doWhat = await Swal.fire({
            text: 'Smazat fotografii '+image.caption+'?',
            icon: 'question',
            cancelButtonText: 'Ne',
            showCancelButton: true,
            focusCancel: true,
            confirmButtonColor: '#BB2D3B',
            confirmButtonText: 'Ano',
        })
        if (doWhat.isConfirmed) {

            const response = await fetch('admin/image/' + image._id, fetchProps('DELETE'));
            const parsedResponse = await response.json();

            if (response.status === 200) {
                await reloadGallery(image.gallery)
            } else {
                await Swal.fire(swalDefaultError(parsedResponse.errorCode))
            }
        }
    }

    const handleMakeImageFirstClick = async (image) => {
        const gallery = {
            id: image.gallery,
            firstImagePath: image.path,
            firstImageCaption: image.caption,
            firstImageWidth:image.originalWidth,
            firstImageHeight:image.originalHeight
        }

        const response = await fetch('/admin/gallery', fetchProps('PUT', gallery))
        const parsedResponse = await response.json();

        if (response.status === 200) {
            await reloadGallery(image.gallery);
            setEditGallery({...editGallery, firstImage: image.path, firstImageCaption: image.caption})
        } else {
            await Swal.fire(swalDefaultError(parsedResponse.errorCode));
        }
    }


    const handleEditExhibitImageTextClick = async (image) => {

        const caption = image.caption ? image.caption : '(bez názvu)';

        const doWhat = await Swal.fire({
            title: 'Upravit text fotografie '+ caption,
            text: `zanak "*" bude použit jako oddělovač pro nový řádek`,
            icon: 'info',
            input: 'text',
            width: '50%',
            inputValue: image.text,
            cancelButtonText: 'Zrušit',
            showCancelButton: true,
            confirmButtonColor: '#BB2D3B',
            confirmButtonText: 'Uložit',
        });

        if (doWhat.isConfirmed) {
            const updatedImage = {
                id: image._id,
                text: doWhat.value.trim() ?? null
            }

            const response = await fetch('/admin/image', fetchProps('PUT', updatedImage))
            const parsedResponse = await response.json();

            if (response.status === 200) {
                await reloadGallery(image.gallery);
            } else {
                await Swal.fire(swalDefaultError(parsedResponse.errorCode))
            }
        }
    }


    const reloadGallery = async (galleryId) => {
        const title = editGallery.title;
        const text = editGallery.text;
        const response = await fetch('/admin/gallery/'+galleryId, fetchProps());
        const {gallery} = await response.json();

        if (response.status === 200) {
            setEditGallery({...editGallery, images: gallery, title: title, text: text, id: galleryId})
            setNewImageOrder(gallery)
        } else {
            await Swal.fire(swalDefaultError(gallery.errorCode))
        }
    }


    return (
        <>
            <Button variant={'warning'}  className={'mt-4'} onClick={handleBackToSelectSectionClick}>
                <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;&nbsp;&nbsp;zpět na výběr kategorie
            </Button>
            {section.hasSubSection && <Button variant={'secondary mt-4'} className={'ml-2'} style={{marginLeft: '1rem'}} onClick={handleBackToSectionClick}>
                <FontAwesomeIcon icon={faRotateBack}/>&nbsp;&nbsp;&nbsp;zpět do sekce {section.label}
            </Button>}
            <Button variant={'outline-primary'} className={'mt-4'} style={{float:'right'}} onClick={handleShowModal}>
                <FontAwesomeIcon icon={faEdit}/>
                {!sectionIsBlog && <span>&nbsp;&nbsp;&nbsp;upravit galerii</span>}
                {sectionIsBlog && <span>&nbsp;&nbsp;&nbsp;upravit příspěvek</span>}
            </Button>
            {editGallery.images.length > 1 && <Button variant={'outline-danger'} className={'mt-4'} style={{float: 'right', marginRight: '1rem'}} onClick={handleShowSortModal}>
                <FontAwesomeIcon icon={faSort}/>
                <span>&nbsp;&nbsp;&nbsp;pořadí fotografií</span>
            </Button>}
            <hr className={'content-lines-wrapper'} />
            <h2 className={'mb-4'}>{section.label}
                {section.hasSubSection && <span> - {editGallery.title}</span>}
            </h2>
            <Table striped bordered variant={'dark'}>
                <thead>
                <tr>
                    <th>název</th>
                    <th>původní rozměr</th>
                    <th>náhled</th>
                    {sectionIxExhibit && <th className={'text-center'}>text</th>}
                    {section.hasSubSection && <th className={'text-center'}>#1</th>}
                    <th className={'text-center'}>smazat</th>
                </tr>
                </thead>
                <tbody>
                {_sortImages(editGallery.images).map(image => {
                    return (
                        <tr>
                            {image.caption && <td className={'align-middle'}>{image.caption}</td>}
                            {!image.caption && <td className={'align-middle'} style={{fontStyle: 'italic', color: 'grey'}}>{image.originalName}</td>}
                            <td className={'align-middle'}>{image.originalWidth}x{image.originalHeight}</td>
                            <td className={'align-middle'}><img src={serverFilesRoot + image.path} alt={''} style={{maxHeight: '100px'}}/></td>
                            {sectionIxExhibit &&
                                <td className={'align-middle text-center'}>
                                    {!image.text && <Button variant={'success'} size={'sm'} onClick={() => handleEditExhibitImageTextClick(image)}><FontAwesomeIcon icon={faPlus} /></Button>}
                                    {image.text && <Button variant={'warning'} size={'sm'}  onClick={() => handleEditExhibitImageTextClick(image)}><FontAwesomeIcon icon={faEdit} /></Button>}
                                </td>}
                            {section.hasSubSection && <td className={'align-middle text-center'}>
                                {image.path === editGallery.firstImage ?
                                    <FontAwesomeIcon icon={faCheckCircle}/> :
                                    <Button size={'sm'} variant={'warning'}><FontAwesomeIcon icon={faStar} onClick={() => handleMakeImageFirstClick(image)}/></Button>}
                            </td>}
                            <td className={'align-middle text-center'}>
                                {(image.path !== editGallery.firstImage || !section.hasSubSection) && <Button onClick={() => handleDeleteImageClick(image)} variant={'danger'} size={"sm"}><FontAwesomeIcon icon={faTrashCan}/></Button>}
                            </td>
                        </tr>
                    )
                })}
                </tbody>

            </Table>
            {editGallery.images?.length === 0 && <p>zatím zde nejsou ždáné fotografie..</p>}

            {/* EDIT modal*/}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size={'xl'}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>Upravit {editGallery.title}</span>
                        {section.hasSubSection && <span style={{fontWeight: 'bold', fontStyle: 'italic'}}> v sekci {section.label}</span>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditGallery
                        mySettings={mySettings}
                        section={section}
                        galleries={galleries}
                        sectionIsBLog={sectionIsBlog}
                        editGallery={editGallery}
                        setEditGallery={setEditGallery}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* SORT modal*/}
            <Modal
                show={showSortModal}
                onHide={handleCloseSortModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>Změnit pořadí fotografií v galerii {editGallery.title}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReorderList
                        newOrder={newImageOrder}
                        setNewOrder={setNewImageOrder}
                        serverFilesRoot={serverFilesRoot}
                        sortWhat={'images'}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSortModal}>
                        Zrušit
                    </Button>
                    <Button variant="success" onClick={handleSaveNewOrderClick}>
                        Uložit a zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
