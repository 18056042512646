import { Draggable } from "react-drag-reorder";
import ListGroup from 'react-bootstrap/ListGroup';

export default function ReorderList(props) {

    const {newOrder, setNewOrder, serverFilesRoot, sortWhat} = props

    const getChangedPos = (currentPos, newPos) => {
        setNewOrder((newOrder) => {
            const currentOrderCopy = [...newOrder];
            [currentOrderCopy[currentPos], currentOrderCopy[newPos]] = [currentOrderCopy[newPos], currentOrderCopy[currentPos]];
            return currentOrderCopy;
        });
    };

    return (
        <ListGroup className="flex-container m-3">
            <Draggable onPosChange={getChangedPos}>
                {newOrder.map((item, index) => {
                    return (
                        <ListGroup.Item className="row" key={index} >
                            <div className="flex-item">
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <span style={{alignItems: "center"}}>{sortWhat === 'images' ? item.originalName : item.title}</span>
                                    <img src={sortWhat === 'images' ?  serverFilesRoot + item.path : serverFilesRoot + item.firstImagePath} alt={''} style={{maxHeight: '45px'}}/>
                                </div>
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </Draggable>
        </ListGroup>
    );
}