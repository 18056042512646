import {useState, useEffect} from "react";
import Swal from 'sweetalert2'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';


import {Form, Button} from "react-bootstrap";
import {fetchProps, editorConfig, swalDefaultError} from '../config';
import MyFileManager from "../components/MyFileManager";
import MyEmojiList from "./MyEmojiList";

export default function EditGallery(props) {

    const {section, sectionIsBLog, editGallery, setEditGallery, galleries, mySettings} = props;

    const [galleryIsSaved, setGalleryIsSaved] = useState(false)
    const [editorData, setEditorData] = useState(editGallery.text)
    const [titleInput, setTitleInput] = useState(editGallery.title)

    useEffect(() => {
        setTimeout(() => setGalleryIsSaved(false), 2000)
    },[galleryIsSaved === true])

    const handleTitleInputChange = (event) => setTitleInput(event.currentTarget.value);

    const handleEditGallerySubmit = async (event) => {
        event.preventDefault()
        const gallery = {
            title: event.currentTarget.title.value,
            text: editorData ?? null,
            id: editGallery.id,
        }

        const response = await fetch('/admin/gallery', fetchProps('PUT', gallery))
        const parsedResponse = await response.json();

        if (response.status === 200) {
            setEditGallery({...editGallery, ...gallery});
            setGalleryIsSaved(true);
        } else {
            await Swal.fire(swalDefaultError(parsedResponse.errorCode))
        }
    }

    return (
        <>
            {section.hasSubSection && <Form onSubmit={(event) => handleEditGallerySubmit(event)}>
                <Form.Group controlId={'title'}>
                    <Form.Label>
                        {!sectionIsBLog && <span>Název galerie *</span>}
                        {sectionIsBLog && <span>Titulek článku *</span>}
                    </Form.Label>
                    <Form.Control required type={'text'} value={titleInput} onChange={handleTitleInputChange}/>
                    <Form.Text className={'text-muted'}>
                        {!sectionIsBLog && <span>Název galerie se zobrazuje spolu s náhledovým obrázkem galerie</span>}
                        {sectionIsBLog && <span>Titulek článku bude použit i jako název jeho galerie</span>}
                    </Form.Text>
                </Form.Group>
                <br/>
                {sectionIsBLog && <MyEmojiList/>}
                {sectionIsBLog && <div className="ck-body-wrapper"><CKEditor
                    editor={Editor}
                    data={editGallery.text}
                    onChange={(event, editor) => setEditorData(editor.getData())}
                    config={{
                        language: {
                            ui: 'cs',
                            content: 'cs'
                        },
                    }}
                /></div>}
                <Button className={'mt-3'} type={'submit'} variant={galleryIsSaved ? 'success' : 'primary'}>
                    {!galleryIsSaved && sectionIsBLog && <span>Uložit nový název a text</span>}
                    {!galleryIsSaved && !sectionIsBLog && <span>Uložit nový název</span>}
                    {galleryIsSaved && <span>Uloženo</span>}
                </Button>
            </Form>}
            <br />
            <MyFileManager gallery={galleries.find((item) => item._id === editGallery.id)} mySettings={mySettings}/>
        </>
    );
}