import {useState, useEffect} from "react";
import Swal from 'sweetalert2'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import {Form, Button} from "react-bootstrap";
import {fetchProps, editorConfig, swalDefaultError} from '../config';
import MyFileManager from "../components/MyFileManager";
import MyEmojiList from "./MyEmojiList";


export default function AddGallery(props) {

    const {section, sectionIsBLog, setGalleries, mySettings} = props;

    const [savedGallery, setSavedGallery] = useState(null)
    const [editorData, setEditorData] = useState(null)


    const handleSaveGallerySubmit = async (event) => {
        event.preventDefault()
        const gallery = {
            title: event.currentTarget.title.value,
            text: editorData ?? null,
            category: section.label,
            categoryId: section.id,
            categoryUrl: section.url,
            createdAt: Date.now()
        }

        const response = await fetch('/admin/gallery', fetchProps('POST', gallery))
        const parsedResponse = await response.json();

        if (response.status === 200) {
            const {savedGallery, updatedGalleries} = parsedResponse;
            setGalleries(updatedGalleries);
            setSavedGallery(savedGallery);

        } else {
            await Swal.fire(swalDefaultError(parsedResponse.errorCode))
        }
    }

    return (
        <>
            <Form onSubmit={(event) => handleSaveGallerySubmit(event)}>
                <Form.Group controlId={'title'}>
                    <Form.Label>
                        {!sectionIsBLog && <span>Název galerie *</span>}
                        {sectionIsBLog && <span>Titulek článku *</span>}
                    </Form.Label>
                    <Form.Control required type={'text'}/>
                    <Form.Text className={'text-muted'}>
                        {!sectionIsBLog && <span>Název galerie se zobrazuje spolu s náhledovým obrázkem galerie</span>}
                        {sectionIsBLog && <span>Titulek článku bude použit i jako název jeho galerie</span>}
                    </Form.Text>
                </Form.Group>
                <br />
                {sectionIsBLog && <MyEmojiList/>}
                {sectionIsBLog &&
                    <div className="ck-body-wrapper"><CKEditor
                    disabled = {!!savedGallery}
                    editor = {Editor}
                    onChange = {(event, editor) => setEditorData(editor.getData())}
                    config = {{
                        language: {
                            ui: 'cs',
                            content: 'cs'
                        },
                    }}
                    /></div>}
                {!savedGallery && <Button className={'mt-3'} type={'submit'}>Uložit a přejít k nahrání fotografií</Button>}
                {savedGallery && <Button className={'mt-3'} variant={'success'} type={'button'} disabled>Uloženo</Button>}
            </Form>
            <br />
            {savedGallery && <MyFileManager gallery={savedGallery} mySettings={mySettings}/>}
        </>
    );
}